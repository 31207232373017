<template>
  <div>

    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext="'Auftragsverwaltung'" :subtext="''"></HeadText>
          </div>

          <div class="container bg-light pt-3 px-3">
            <b-row>
              <b-col class="text-left">
                <form ref="topAnchor"></form>
                <h4 class="mb-5" v-if="isAuftragChange">
                  Vielen Dank für die Auftragsänderung.
                </h4>
                <h4 class="mb-5" v-else>
                  Vielen Dank für Ihre Auftragserteilung.
                </h4>
                <p v-if="isAuftragChange">
                  Die Details Ihres geänderten Auftrags #{{this.getAuftragNr}} können Sie <router-link :to="{name: 'auftragsdetails', params: {client: client, id: this.getAuftragId}}">hier</router-link> ansehen, in wenigen Minuten sind dort auch Ihre Dokumente zum Download verfügbar.
                </p>
                <p v-else>
                  Die Details Ihres neuen Auftrags #{{this.getAuftragNr}} können Sie <router-link :to="{name: 'auftragsdetails', params: {client: client, id: this.getAuftragId}}">hier</router-link> ansehen, in wenigen Minuten sind dort auch Ihre Dokumente zum Download verfügbar.
                </p>

                <div v-if="this.loadingSubsiteContent" class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Die Daten werden geladen...</strong>
                </div>

                <div v-if="!this.loadingSubsiteContent && this.auftragsTyp === 'KAUF' && !isAuftragChange">
                  <div v-html="this.auftragKaufAngelegt"></div>
                </div>

                <div v-if="!this.loadingSubsiteContent && this.auftragsTyp === 'VERKAUF' && !isAuftragChange">
                  <div v-html="this.auftragVerkaufAngelegt"></div>
                </div>

                <div v-if="!this.loadingSubsiteContent && this.auftragsTyp === 'KAUF' && isAuftragChange">
                  <div v-html="this.auftragKaufBearbeitet"></div>
                </div>

                <div v-if="!this.loadingSubsiteContent  && this.auftragsTyp === 'VERKAUF' && isAuftragChange">
                  <div v-html="this.auftragVerkaufBearbeitet"></div>
                </div>

              </b-col>
            </b-row>
          </div>

        </div>
      </Hero>
    </section>  
  </div>
</template>

<script>
import axios from "axios";
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import {mapGetters} from "vuex";

export default {
  name: "AuftragsVerwaltungThanks",
  components: {Hero, HeadText,},
  data() {
    return {
      loadingSubsiteContent: false,

      auftragKaufAngelegt: '',
      auftragVerkaufAngelegt: '',
      auftragKaufBearbeitet: '',
      auftragVerkaufBearbeitet: '',
    }
  },
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  methods: {
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected?contentTypes=32280,32290,32300,32310';
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data.content;
            this.loadingSubsiteContent = false;
            this.auftragKaufAngelegt = this.getHtmlContent( 32280 );
            this.auftragVerkaufAngelegt = this.getHtmlContent( 32290 );
            this.auftragKaufBearbeitet = this.getHtmlContent( 32300 );
            this.auftragVerkaufBearbeitet = this.getHtmlContent( 32310 );
          } )
          .catch(error => {
            console.log( error );
            this.loadingSubsiteContent = true;
          })
    },
    getHtmlContent( contentType ) {
      for ( let item in this.subsiteContent ) {
        var contentItem = this.subsiteContent[item];
        if ( contentType === contentItem.contentType.id ) {
          return contentItem.htmlcontent;
        }
      }
      return "No content for ID " + contentType;
    },
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    onComplete() {
      alert("Yay. Done!");
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  computed: {
    ...mapGetters('auftragswizard', ['auftragsTyp']),
    client() {
      return this.$store.getters['clientConfig/client']
    },
    isAuftragChange() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const edit = urlParams.get('edit');
      if(edit) {
        return true
      } else {
        return false
      }
    },
    getAuftragId() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get('id');
      return id;
    },
    getAuftragNr() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const auftragNr = urlParams.get('auftragNr');
      return auftragNr;
    }
  },
  mounted() {
    this.loadSubsiteContent();
    this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
  },
}
</script>

<style lang="scss">

</style>